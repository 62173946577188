<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :width="500"
  >
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="$t('consumption.updateConsumptionDevice')"
        />
        <v-spacer />
        <v-btn
          color="white"
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-6"
        style="max-height:65vh; height:auto; overflow:auto;"
      >
        <v-row class="pt-2">
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-checkbox
              v-model="device.isActive"
              :label="device.isActive ? $t('common.active') : $t('common.inActive')"
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <v-textarea
              v-model="device.description"
              label="Description"
              row-height="15"
              auto-grow
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          @click.native="updateDeviceInfo"
        >
          {{ $t('common.update') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },

      device: {
        type: Object,
        default: () => null,
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    methods: {
      updateDeviceInfo() {
        this.$emit('update-device')
      },
    },
  }
</script>
