<template>
  <base-card>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :items-per-page="limit"
        hide-default-footer
        item-key="deviceId"
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4 mainText--text"
      >
        <template v-slot:item.description="{item}">
          <div v-html="util.convertTextToHtml(item.description)" />
        </template>

        <template v-slot:item.isActive="{item}">
          <span>{{ item.isActive ? $t('common.yes') : $t('common.no') }}</span>
        </template>

        <template v-slot:item.lastUpdatedAt="{item}">
          <span>{{ util.formatDateTime(item.lastUpdatedAt, 'MMM DD, hh:mm A') }}</span>
        </template>

        <template v-slot:item.action="{item}">
          <v-btn
            class="ma-1"
            fab
            x-small
            color="success"
            @click.native="showUpdateDeviceDialog(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <UpdateConsumptionDeviceDialog
      v-model="isShowUpdateDeviceDialog"
      :device="selectedDevice"
      @update-device="updateDeviceInfo"
    />
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import _ from 'lodash'

  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
  } from '@/utils/swalDialog'

  import UpdateConsumptionDeviceDialog from './UpdateConsumptionDeviceDialog'

  export default {
    metaInfo: {
      title: 'Consumption Devices',
    },

    components: {
      UpdateConsumptionDeviceDialog,
    },

    data () {
      return {
        tableData: [],
        headers: [{
          text: this.$t('common.number'),
          value: 'index',
          width: 80,
          sortable: false,
        }, {
          text: this.$t('consumption.deviceId'),
          value: 'deviceId',
          width: 220,
          sortable: false,
        }, {
          text: this.$t('common.description'),
          value: 'description',
          sortable: false,
        }, {
          text: this.$t('common.active'),
          value: 'isActive',
          width: 150,
          sortable: false,
        }, {
          text: this.$t('consumption.lastUpdatedAt'),
          value: 'lastUpdatedAt',
          width: 200,
          sortable: false,
        }, {
          text: this.$t('common.action'),
          value: 'action',
          width: 90,
          sortable: false,
        }],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        errorMessage: '',
        isShowUpdateDeviceDialog: false,
        selectedIndex: -1,
        selectedDevice: {},

        util: util,
      }
    },

    watch: {
      currentPage(val) {
        this.loadDevices(val)
      }
    },

    mounted () {
      this.loadDevices()
    },

    methods: {
      ...mapActions({
        getConsumptionDevices: 'getConsumptionDevices',
        updateConsumptionDevice: 'updateConsumptionDevice',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadDevices(currentPage = 1) {
        this.currentPage = currentPage
        this.errorMessage = ''

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)

        try {
          const payload = {
            offset: (currentPage - 1) * this.limit,
            limit: this.limit,
          }

          const response = await this.getConsumptionDevices(payload)

          this.totalPages = Math.ceil(response.count / this.limit)
          this.tableData = response.rows.map((item, index) => ({
            ...item,
            index: (currentPage - 1) * this.limit + index + 1,
          }))
        } catch (error) {
          this.totalPages = 1
          this.tableData = []
          this.currentPage = 1
          this.errorMessage = util.getErrorResponse(error)
        }
        this.setLoading(false)
      },

      showUpdateDeviceDialog (row) {
        this.selectedDevice = _.cloneDeep(row)
        this.selectedIndex = (row.index - 1) % this.limit
        this.isShowUpdateDeviceDialog = true
      },

      async updateDeviceInfo () {
        this.isShowUpdateDeviceDialog = false

        this.setLoadingText(this.$t('consumption.updatingConsumptionDevice'))
        this.setLoading(true)

        try {
          await this.updateConsumptionDevice(this.selectedDevice)
          await this.loadDevices(this.currentPage)

          showSuccessDialog(this.$t('consumption.updatedConsumptionDevice'))
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorMessage)
        }

        this.setLoading(false)
      }
    },
  }
</script>
